import React from 'react';
import { Layout } from '../components/Layout';
import imageKevin from '../images/aboutUs/k-nemrava-3.jpg';

const AboutUs = () => (
  <Layout
    title="About Us | Axiom Geomatics | Calgary, Red Deer"
    description="A full-service survey/geomatics company focusing on individual and small clients, specializing in Real Property Reports, subdivisions and construction surveys."
    keywords=""
  >
    <main>
      <h1>About Us</h1>

      <img
        className="img img-right img--width-max33"
        src={imageKevin}
        height="295"
        width="295"
        alt="KeEvin Nemrava, Founder"
      />

      <p>
        Axiom Geomatics is a <strong>full-service legal survey/geomatics company</strong>{' '}
        headquartered in Calgary, Alberta. The company is anchored by its founder and president,
        Kevin Nemrava, A.L.S., P. Eng, who brings nearly two decades of industry experience and
        knowledge.
      </p>

      <p>
        We have a diverse skillset including competencies in{' '}
        <a href="https://axiomgeomatics.ca/services/residential-surveys">Land Surveying</a>,{' '}
        <a href="https://axiomgeomatics.ca/services/construction-services/engineering-surveys">
          Engineering
        </a>
        , drafting, and calculations. I personally believe in the deepest commitment to an
        exceptional customer experience.
      </p>

      <p>
        The company focuses primarily on individual and small clients for{' '}
        <a href="https://axiomgeomatics.ca/services/residential-surveys">residential</a> and{' '}
        <a href="https://axiomgeomatics.ca/services/legal-surveys/subdivision-surveys">
          municipal survey
        </a>{' '}
        needs. Our customers remark on the level of service — communication, pricing and speed —
        typically only available to larger corporate clients.
      </p>

      <p>
        We look forward to developing strong relationships with our clients and communities based on
        integrity, reliability and professionalism.
      </p>

      <p className="center">
        <em>
          Axiom Geomatics: we specialise in Real Property Reports (RPR's), subdivision, condominium
          and construction surveys.
        </em>
      </p>
    </main>
  </Layout>
);

export default AboutUs;
